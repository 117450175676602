@mixin spinkit-theme($background) {
  .sk-primary.sk-rotating-plane,
  .sk-primary.sk-double-bounce .sk-child,
  .sk-primary.sk-wave .sk-rect,
  .sk-primary.sk-wandering-cubes .sk-cube,
  .sk-primary.sk-spinner-pulse,
  .sk-primary.sk-chasing-dots .sk-child,
  .sk-primary.sk-three-bounce .sk-child,
  .sk-primary.sk-circle .sk-child:before,
  .sk-primary.sk-cube-grid .sk-cube,
  .sk-primary.sk-fading-circle .sk-circle:before,
  .sk-primary.sk-folding-cube .sk-cube:before {
    background-color: $background;
  }
}
