@import "~src/vendor/styles/_appwork/functions";

@mixin sweetalert2-theme($background, $color: null) {
  $color: if($color, $color, yiq($background));

  .swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step,
  .swal2-progress-steps[class] .swal2-progress-step-line,
  .swal2-progress-steps[class] .swal2-active-progress-step,
  .swal2-progress-steps[class] .swal2-progress-step {
    background: $background;
    color: $color;
  }

  .swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step,
  .swal2-progress-steps[class] .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
    background: rgba-to-hex(rgba($background, .25));
  }
}
